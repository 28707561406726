<template>
  <v-card tile flat class="blue-grey lighten-5">
    <v-card-title>Add/Edit Custom Field</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-container fluid pa-0>
        <v-row no-gutters class="mb-2">
          <v-col cols="2" class="text-right align-self-center black--text">Name</v-col>
          <v-col cols="4" class="ml-3">
            <v-text-field
              hide-details
              v-model="customField.name"
              label="Name"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-2">
          <v-col cols="2" class="text-right align-self-center black--text">Default Value</v-col>
          <v-col cols="4" class="ml-3">
            <v-text-field
              hide-details
              v-model="customField.default"
              label="Default Value"
              outlined
              dense
              solo
              flat
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn depressed color="green" class="white--text" @click="saveCustomField">Save</v-btn>
      <v-btn depressed color="red" class="white--text" @click="cancel">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  name: "customer",
  components: {},
  data() {
    return {
      customField: {
        name: null,
        default: null,
        org_idx: localStorage.getItem("orgId")
      }
    };
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  beforeRouteUpdate(to, from, next) {
    next(vm => {
      var self = vm;
      if (self.$router.currentRoute.name == "editcustomfield") {
        self.customField = Object.assign({}, self.$route.params);
      } else {
        self.customField = {
          name: null,
          default: null,
          org_idx: localStorage.getItem("orgId")
        };
      }
    });
  },
  mounted() {
    var self = this;
  },
  methods: {
    saveCustomField() {
      var self = this;
      this.$store
        .dispatch({
          type: "MDMStore/upsertOrgCustomField",
          data: this.customField
        })
        .then(data => {
          this.$store.commit("MDMStore/SET_SNACKBAR_DETAILS", {text:"Custom field updated successfully", status:"success"});
          this.$store.dispatch({
            type: "MDMStore/getOrgCustomFields",
            orgId: localStorage.getItem("orgId")
          });
           this.$router.go(-1);
        });
    },
    cancel() {
      this.$router.go(-1);
    }
  }
};
</script>
